<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  },
  mounted() {
    setTimeout(()=>{
      console.log(this.$router);
    },3000)
  }
}
</script>
<style lang="scss">

</style>
