const personals = [
  {
    path: '/notice',
    name: 'notice',
    component: () => import('@/views/notice/notice.vue'),
    meta: {
      title: "公告信息",
    }
  },
  {
    path: '/recruitPost',
    name: 'recruitPost',
    component: () => import('@/views/recruitPost/recruitPost.vue'),
    meta: {
      title: "招聘岗位报名",
    }
  },
  {
    path: '/personalRecord',
    name: 'personalRecord',
    component: () => import('@/views/personalRecord/personalRecord.vue'),
    meta: {
      title: "个人报名记录",
    }
  }
]

const administrations = [
  {
    path: '/noticeInformation',
    name: 'noticeInformation',
    component: () => import('@/views/noticeInformation/noticeInformation.vue'),
    meta: {
      title: "公告信息管理",
    }
  },
  {
    path: '/recruitmentMatters',
    name: 'recruitmentMatters',
    component: () => import('@/views/recruitmentMatters/recruitmentMatters.vue'),
    meta: {
      title: "招聘事项管理",
    }
  },
  {
    path: '/registrationReview',
    name: 'registrationReview',
    component: () => import('@/views/registrationReview/registrationReview.vue'),
    meta: {
      title: "报名审核管理",
    }
  },
  {
    path: '/registrationStatistics',
    name: 'registrationStatistics',
    component: () => import('@/views/registrationStatistics/registrationStatistics.vue'),
    meta: {
      title: "报名统计管理",
    }
  },
  {
    path: '/dictionaries',
    name: 'dictionaries',
    component: () => import('@/views/dictionaries/dictionaries.vue'),
    meta: {
      title: "字典",
    }
  },
  {
    path: '/userManagement',
    name: 'userManagement',
    component: () => import('@/views/userManagement/userManagement.vue'),
    meta: {
      title: "用户管理",
    }
  }
]

export default {
  personals,
  administrations
}