import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueQuillEditor from 'vue-quill-editor'
import {getToken} from '@/utils/auth'
import obj from '../vue.config'

 
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import './styles/personalInformation.scss'
import './styles/index.scss'
Vue.use(VueQuillEditor);
Vue.use(ElementUI);
Vue.config.productionTip = false

Vue.prototype.$configs = obj.devServer.proxy['/api'].target;
// let vm = {};

// console.log(store);
let token = null;
router.beforeEach((to, from, next) => {
  let ownTo = window.sessionStorage.getItem('ownToken');
  store.commit('SET_NAV',to.meta.title)
  // 判断是管理端，还是个人端
  if(ownTo){
    token = getToken()=='undefined'?'':getToken();
    Vue.prototype.$ownToken = getToken();
  }else{
    token = getToken()=='undefined'?'':getToken();
    Vue.prototype.$ownToken = null;
  }

  if(token){
    if(store.getters.menuFlag){
      store.dispatch('GenerateRoutes').then((res)=>{
        router.addRoutes(store.getters.addRoutes) // 动态添加可访问路由表
        next({ ...to,replace: true }); // hack方法 确保addRoutes已完成
      })
    }else{
      next()
    }
  }else{
    if(to.path!=='/login'){
      next({
        path:'/login',
        query:{redirect:to.fullPath}
      })
    }else{
      next()
    }
  }
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
