import Vue from 'vue'
import Vuex from 'vuex'
import juris from '@/router/juris'
import router from '@/router'
import {getToken} from '@/utils/auth'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    signupObj:{},
    navObj:{},
    routes: [],
    addRoutes: [],
    menuFlag:true
  },
  mutations: {
    SET_signup: (state,obj)=>{
      state.signupObj = obj;
    },
    SET_NAV:(state,obj)=>{
      state.navObj = obj;
    },
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes
    },
    SET_FLAG:(state,t)=>{
      state.menuFlag = t;
    }
  },
  actions: {
    GenerateRoutes({
      commit
    }, menus) {
      let ownTo = window.sessionStorage.getItem('ownToken');
      return new Promise(resolve=>{
        let accessedRoutes = [];
        let routesAry = router.options.routes;
        if(ownTo||getToken().length<50){
          routesAry[1].children = juris.personals
          accessedRoutes = routesAry;
          if(!ownTo){
            window.sessionStorage.setItem('ownToken','ownToken')
          }
        }else if(getToken().length>40){
          routesAry[1].children = juris.administrations
          accessedRoutes = routesAry
        }
        commit('SET_FLAG',false)
        commit('SET_ROUTES', accessedRoutes)
        resolve(accessedRoutes)
      })
    }
  },
  modules: {
    
  },
  getters:{
    signupObj:state=>state.signupObj,
    navObj:state=>state.navObj,
    addRoutes:state=>state.addRoutes,
    menuFlag:state=>state.menuFlag,
  }
})
